import React from "react"
import { FaLongArrowAltRight } from "react-icons/fa";
import ServiceData from "../ProductsList/ProductsList.tsx";
import { Link } from "react-router-dom";

import "../Components/FeaturesWapper/Features.css"

const InteriorServices = () => {

  return (
    <div className="ServiceWapper py-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="pageHeading flex-column">
              <div className="h1-image">
                <h1>Interiors Fitout Works </h1>
              </div>
              <p>
                Below are the <strong>Interiors Fitout Services</strong> we offers
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <ul className="col-12 col-md-12 mx-auto service d-flex gap-4 flex-wrap align-items-center justify-content-center justify-content-lg-start justify-content-xl-between">
            {ServiceData.map((data, index) => (
              <li className="features-list" key={index}>
                <div>
                  <img src={data.image} style={{width:"100px", height:"100px"}} alt="" />
                </div>
                <div>
                  <h5>{data.name}</h5>
                </div>
                <div>
                  <span>{data.description}</span>
                </div>
                <Link to={"/contact"} className="h-25 text-danger knowmore">Know more <FaLongArrowAltRight className="fill-red" /></Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InteriorServices;
