import React from "react";
import "./aboutRoute.css";
import design from "../Image/design.jpeg";
import design2 from "../Image/design2.jpeg";
import logo from "../Image/logo.png"

export default function About() {
    return (
      <div className="aboutRouter">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>About Us</h1>
            </div>
            <div className="col-12">
              <div className="logoImage">
              <img src={logo} style={{width: "300px", height: "250px"}} alt="design"></img>
              </div>
              <p>
              Welcome to Sherani Interior Decoration, your trusted partner for comprehensive interior and technical services. 
              With a commitment to excellence and a passion for innovation, we strive to transform spaces into captivating environments that inspire, function, and endure.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
              <h5>Our Vision</h5>
              <p>
              At Sherani Interior Decoration, we envision a world where every space reflects the unique identity and purpose of its occupants. 
              We aim to set new standards in interior design and technical services, creating harmonious environments that elevate living, working, and recreational experiences.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
              <h5>Our Mission</h5>
              <p>
              Our mission is to deliver unparalleled interior and technical solutions tailored to the specific needs and aspirations of our clients. 
              We blend creativity, expertise, and cutting-edge technology to bring visions to life, while prioritizing sustainability, efficiency, and cost-effectiveness.
              </p>
            </div>
            <div className="col-6 d-none d-md-block">
            <img className="image" src={design} style={{width: "550px", height: "400px"}} alt="design"></img>
            </div>
            <div className="col-6 d-none d-md-block">
            <img className="image" src={design2} style={{width: "550px", height: "400px"}} alt="design"></img>
            </div>
            <div className="col-12 mt-5">
              <h5 className="listHeading">Whats Sets Us Apart</h5>
              <ul>
                <li>
                  <strong>Expertise:</strong>
                  Our team comprises skilled professionals with diverse backgrounds in interior design, 
                  architecture, engineering, and technical services. Their collective expertise enables us to tackle projects of any scale or complexity with confidence and precision.
                </li>
                <li>
                  <strong>Innovation:</strong>
                  We embrace innovation as a driving force behind our success. Constantly exploring new materials, techniques, and technologies, 
                  we push the boundaries of whats possible, ensuring that our solutions are always at the forefront of industry trends.
                </li>
                <li>
                  <strong>Collaboration:</strong>
                  We believe in the power of collaboration and foster open communication with our clients, consultants, and partners throughout every stage of the project. 
                  By listening attentively and understanding their goals, we can deliver tailored solutions that exceed expectations.
                </li>
                <li>
                  <strong>Sustainability: </strong>
                  Environmental responsibility is at the core of our business philosophy. 
                  We integrate sustainable practices into our designs and operations, minimizing environmental impact and promoting resource conservation for a greener future.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  };