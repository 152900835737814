import React from "react";
import { useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import AOS from "aos"
import 'aos/dist/aos.css';

import { IoLogoWhatsapp, IoLogoFacebook, IoIosCall } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";

import "/node_modules/bootstrap/dist/js/bootstrap.bundle";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";

import livingRoom from "../../Image/white-living-room.jpg";

import CompanySuccess from '../CompanySuccess/CompanySuccess.tsx';
import About from '../About/About.tsx';
import Approvals from '../Approvals/Approvals.tsx';
import Features from '../FeaturesWapper/Features.tsx';
import ContantWapper from '../ContactWapper/ContantWapper.tsx';
import { Link } from 'react-router-dom';


const Home = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    })
  }, [])

  return (
    <main>
      <div className="backgroundImage">
        <div className="container">
          <div className={`row h-100 rowHeight align-items-center`}>
            <div className="col-md-7 col-lg-7 col-xl-6 col-12">
              <h1>
                <Typewriter
                options={{
                  strings: ['Sherani'],
                  autoStart: true,
                  loop: true,
                }}
                />
              </h1>
              <h5>
                <Typewriter
                 options={{
                  strings: ['Interior Decoration'],
                  autoStart: true,
                  loop: true,
                 }}
                />
              </h5>
              <sub className="animate">Make your interior and exterior desgin interesting and comfortable to your living
                <div className="floteLeft">
                  <span><Link to="https://api.whatsapp.com/send/?phone=971529251786&text=&type=phone_number&app_absent=0"><IoLogoWhatsapp /></Link></span>
                  <span><Link to="https://www.instagram.com/sherani_interiordecoration/"><FaInstagramSquare /></Link></span>
                  <span><Link to="https://www.facebook.com/profile.php?id=61556282277884"><IoLogoFacebook /></Link></span>
                  <span><Link to="tel:+971 52 925 1786"><IoIosCall /></Link></span>
                </div>
              </sub>
            </div>
            <div data-aos="fade-up" data-aos-once="true" className="col-md-5 col-lg-5 col-xl-6 d-none d-md-flex align-items-center justify-content-center">
              <img src={livingRoom} className='w-auto' style={{height: "400px"}} alt='livingRoom'></img>
            </div>
          </div>
          <CompanySuccess />
        </div>
      </div>
      <Approvals />
      <Features />
      <About />
      <ContantWapper />
    </main>
  );
}

export default Home;
