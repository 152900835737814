import React from "react";
import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { FaLongArrowAltRight } from "react-icons/fa";

import "./Features.css";

import { Link, useNavigate } from "react-router-dom";

const Features = () => {
  const router = useNavigate();
  const Date = [ "Interiors Fitout Works", "Maintenances Services" ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    })
  }, [])


  const onClickHandler = () => {
    setTimeout(scrollToSection, 1000);
  };

  const onClickHandler2 = (link: string) => {
    link === "Interiors Fitout Works" ? router(`/interiorServices`) : router(`/maintenancesServices`);;
  };

  const scrollToSection = () => {
    // Calculate the target position you want to scroll to
    const targetPosition = document.getElementById('contact')?.offsetTop;

    // Scroll to the target position
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth' // You can use 'auto' for instant scroll
    });
  }

  return (
    <div className="featuresWapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="pageHeading flex-column">
              <div className="h1-image">
                <h1>What’s Our Services</h1>
              </div>
              <p>
                Below are the types of services we provide
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <ul data-aos="fade-up" data-aos-once="true" className="col-12 col-md-12 mx-auto featureService d-flex gap-4 flex-wrap align-items-center justify-content-center">
            {Date.map((data, index) => (
              <li className="features-list" key={index}>
                  <h5 onClick={onClickHandler2.bind(null, data)}>{data}</h5>
                <div onClick={onClickHandler} className="h-25 text-danger knowmore">Know more <FaLongArrowAltRight className="fill-red" /></div>
              </li>
            ))}
          </ul>
        </div>
        <div className="row">
          <div className="col-12 buttonArea">
            <p>For more information click the below button</p>
            <Link to={"tel:+971 52 925 1786"} ><button>Call now</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
