import React from "react";
import { useEffect, useRef } from 'react';
import axios from 'axios';
import AOS from 'aos';
import { ToastContainer, toast } from 'react-toastify';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import { MdEmail, MdPhone } from 'react-icons/md';


import 'react-toastify/dist/ReactToastify.css';
import "../Components/ContactWapper/ContactWapper.css"

const Contant = () => {
    const userName = useRef<HTMLInputElement>(null);
    const userEmail = useRef<HTMLInputElement>(null);
    const userNumber = useRef<HTMLInputElement>(null);
    const userMessage = useRef<HTMLTextAreaElement>(null);


    const onSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = {
            fullName: userName.current!.value,
            email: userEmail.current!.value,
            phoneNumber: userNumber.current!.value,
            message: userMessage.current!.value
        };

        try {
            const response = await axios.post("https://bin-sahoo.onrender.com/api/contact", {...data});

            if (response.status === 200) {
                toast.success('Form submitted successfully!');
            } else {
                toast.error('Form submission failed.');
            }

        } catch (error) {
            console.log('Form submission failed:', error);
        }

        userEmail.current!.value = "";
        userMessage.current!.value = "";
        userName.current!.value = "";
        userNumber.current!.value = "";
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
        })
    }, [])

    const contactList = [
        {
            icons: <IoLocationSharp />,
            heading: "Location",
            description: "FRANCE-R24-02 INTERNATIONAL CITY Dubai UAE",
            link: "https://www.google.com/maps/place/France+R24+-+International+City+-+France+Cluster+-+Dubai+-+United+Arab+Emirates/@25.1633837,55.3961231,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f66b46b3475d7:0x2019a64d844b3188!8m2!3d25.1633789!4d55.398698!16s%2Fg%2F12vqqyz_1?hl=en-US&entry=ttu"
        },
        {
            icons: <MdEmail />,
            heading: "Email",
            description: "info@sheraniinterioruae.com",
            link: "mailto:info@sheraniinterioruae.com"
        },
        {
            icons: <MdPhone />,
            heading: "Phone",
            description: "+971 52 925 1786",
            link: "tel:+971 55 801 7980"
        },
    ];

    return (
        <div id='contact' className='contactWapper'>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-12">
                        <div className="contactHeaderContainer">
                            <h5>Contact Us!</h5>
                            <h1>Get In Touch!</h1>
                            <p>
                                A Private Limited is the most popular type of partnership Malta.
                                The limited liability is, in fact, the only type of company
                                allowed by Companies.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div data-aos="fade-up" data-aos-once="true" className='col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                        <h3>Need Support !</h3>
                        <p>Contact us for a quote, help to join the them.</p>
                        <div className='flexContactInformation'>
                            <form onSubmit={onSubmitHandler} className='formBlock' id='usrform'>
                                <input type="text" name="Name" ref={userName} id="Name" placeholder='Name :' />
                                <input type="email" name="Email" ref={userEmail} id="Email" placeholder='Email :' />
                                <input type="text" name="Number" ref={userNumber} id="Number" placeholder='Number :' className='subject' />
                                <textarea name="message" form="usrform" ref={userMessage} placeholder='Message :' className='message'></textarea>
                                <button type="submit">Send Message</button>
                                <ToastContainer
                                    position="bottom-center"
                                    autoClose={1000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="dark"
                                />
                            </form>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-once="true" className='col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                        <div id="map-container-google-1" className="z-depth-1-half map-container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.1804615155274!2d55.39612307608158!3d25.163383733068176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f66b46b3475d7%3A0x2019a64d844b3188!2sFrance%20R24%20-%20International%20City%20-%20France%20Cluster%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sus!4v1707805296865!5m2!1sen!2sus" allowFullScreen referrerPolicy="no-referrer-when-downgrade" title="map"></iframe>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <ul data-aos="fade-up" data-aos-once="true" className='contactBlock'>
                            {contactList.map(({ icons, heading, description, link }, index) => (
                                <li key={index}>
                                    {icons}
                                    <span>
                                        <h4>{heading}</h4>
                                        <Link to={link}><p>{description}</p> </Link>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Contant;
