import React from "react";
import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

import "./About.css";

const About = () => {
    
    useEffect(() => {
        AOS.init({
          duration: 1000,
          once: false,
        })
      }, [])

    return (
        <div className="w-100 aboutCoodinate">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-12">
                        <div className="card text-left rounded-2">
                            <div className="card-body" data-aos="fade-up" data-aos-once="true">
                                <h5 className="card-title">We create distinctive interiors with a unique atmosphere</h5>
                                <p className="card-text">Welcome to Sherani Interior Decoration, your trusted partner for comprehensive interior and technical services.
                                    With a commitment to excellence and a passion for innovation, we strive to transform spaces into captivating environments that inspire, function, and endure.</p>
                                <a href="/about" className="btn btn-primary">For more</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 imageBlockAboutPage" data-aos="fade-up" data-aos-once="true">
                        <div className="col-6 d-flex flex-column align-items-center">
                            <div className="col-3 border borderImg-1 w-75 rounded-3 mb-2"></div>
                            <div className="col-3 border borderImg-2 w-75 h-25 rounded-3"></div>
                        </div>
                        <div className="col-6">
                            <div className="col-6 border borderImg-3 w-75 h-25 rounded-3 mb-2"></div>
                            <div className="col-6 border borderImg-4 w-75 rounded-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default About;