import img from "../../Image/craftsman-home-repair.jpg";
import img1 from "../../Image/electricity-lighting.jpg";
import img2 from "../../Image/tiles-icon-vector-design.jpg"
import img3 from "../../Image/airconditioning.jpg";
import img4 from "../../Image/cleaning.webp";
import img5 from "../../Image/curtain.webp";
import img6 from "../../Image/glasswork.webp";
import img7 from "../../Image/gypsum.jpg";
import img8 from "../../Image/joinery.webp";
import img9 from "../../Image/marble.jpg";
import img10 from "../../Image/painting.jpg";
import img11 from "../../Image/plaster.webp";
import img12 from "../../Image/plumbing.jpg";
import img13 from "../../Image/turnkey.jpg";
import img14 from "../../Image/wallpaper.webp";

const featuresData = [
  {
    id: "1",
    heading: "Flooring Contracting",
    image: img,
    paragraph:
      "We offer comprehensive flooring maintenance services to help prolong the lifespan and beauty of your floors.",
  },
  {
    id: "2",
    heading: "Electrical Works",
    image: img1,
    paragraph:
      " Regular maintenance is essential to ensure the safety, efficiency, and longevity of your electrical systems.",
  },
  {
    id: "3",
    heading: "False Ceiling Conracting",
    image: img2,
    paragraph:
      "Over time, false ceilings may require repairs due to wear and tear, water damage, or structural issues.",
  },
  {
    id: "4",
    heading: "Turnkey Fitout Work",
    image: img13,
    paragraph:
      "We work with reputable suppliers and manufacturers to source high-quality furniture, fixtures, and equipment that complement your design concept.",
  },
  {
    id: "5",
    heading: "Gypsum Works",
    image: img7,
    paragraph:
      "Transform plain walls into works of art with our gypsum wall cladding solutions..",
  },
  {
    id: "6",
    heading: "Painting Works",
    image: img10,
    paragraph:
      "Transform your interior spaces with our expert interior painting services..",
  },
  {
    id: "7",
    heading: "Air Conditioning, Ventillation",
    image: img3,
    paragraph:
      "We specialize in the installation of high-efficiency air conditioning systems.",
  },
  {
    id: "8",
    heading: "Plumbing Works",
    image: img12,
    paragraph:
      "Our team of licensed plumbers offers a wide range of general plumbing services.",
  },
  {
    id: "9",
    heading: "Marble & Granite Works",
    image: img9,
    paragraph:
      "We specialize in custom fabrication of marble and granite countertops, vanity tops etc.",
  },
  {
    id: "10",
    heading: "Block, Plaster & Cladding Works",
    image: img11,
    paragraph:
      "Our team consists of professionals peolpes with expertise in blockwork, plastering, and cladding techniques.",
  },
  {
    id: "11",
    heading: "Joinery Works",
    image: img8,
    paragraph:
      "We offer custom joinery and millwork services to create bespoke furniture, cabinetry, fixtures, and finishes.",
  },
  {
    id: "12",
    heading: "Cleaning Services",
    image: img4,
    paragraph:
      "We specialize in commercial cleaning services for offices, retail stores etc.",
  },
  {
    id: "13",
    heading: "Wallpaper Works",
    image: img14,
    paragraph:
      "Our team consists of skilled designers with extensive experience in wallpaper installation and design.",
  },
  {
    id: "14",
    heading: "Curtain Works",
    image: img5,
    paragraph:
      "Our professional installers ensure proper placement, alignment, and draping of your curtains.",
  },
  {
    id: "13",
    heading: "Aluminium & Glass Works",
    image: img6,
    paragraph:
      "We specialize in custom aluminum fabrication for windows, doors, storefronts, curtain walls etc.",
  },
];

export default featuresData;
