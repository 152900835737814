import React from "react"
import "./Navigation.css";

import 'bootstrap/dist/css/bootstrap.css'
import { useState, useEffect } from "react"; 
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
    const [activeClass, setActiveClass] = useState('/');
    const url = useLocation();
    
    useEffect(() => { setActiveClass(url.pathname) }, [url, activeClass])

    const onClickHandler = (value: string) => {
        setActiveClass(value);
      };

    const naviLinks = [
        { name: "Home", link: "/"},
        { name: "About", link: "/about"},
        { name: "Interior Services", link: "/interiorServices"},
        { name: "Maintenance Work", link: "/maintenancesServices"},
        { name: "Contact", link: "/contact"},
        { name: "PrivacyPolicy", link: "/privacyPolicy"},
      ];

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <a className="navbar-brand d-flex" href="/" >
                    <div className="logoHeading">Sherani <br /> <span className="subHeading">Interior Decoration</span></div>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <ul className="nav nav-underline justify-content-lg-center gap-lg-5">
                        {naviLinks.map(( {name, link} ,index) => (
                            <li className="nav-item" key={index} >
                            <Link className={`nav-link ${ activeClass === `${link}` ? "active" : ''}`} onClick={onClickHandler.bind(null, link)} to={link}>{name}</Link>
                        </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;