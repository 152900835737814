
import React, { useEffect } from 'react';
import aos from "aos";

import 'aos/dist/aos.css';
import "./style.css";

const PrivacyPolicy = () => {

  useEffect( () => { aos.init({ duration: 2000 })}, [])

  return (
    <div id='privacypolicy' className='backgroundColor w-full h-full py-5'>
      <div className='bg-aboutImage bg-no-repeat bg-contain bg-center flex flex-col items-center justify-center'>
        <div className='container mx-auto max-w-[75rem] w-full h-auto rounded-lg border-4 py-4 border-[#094395]' data-aos="flip-down" data-aos-once="true">
          <div className='backgroundColor w-full h-full flex flex-col gap-y-4 opacity-75 text-lg sm:text-2xl'>
            <div className='flex flex-col items-center justify-center mb-4'>
              <h1 className='text-2xl sm:text-4xl lg:text-5xl philosopher-bold'>Privacy Policy</h1>
              <div className='border-0 rounded-md bg-blue-500 w-28 h-3'></div>
            </div>
            <p className="w-3/4 mx-auto swift-up-text">
              <strong className='philosopher-bold'>Effective Date: 24-02-2024</strong> <br />
              Sherani Interior Decoration is committed to protecting the privacy and security of your personal information.
              This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide
              when you visit our website <strong className='philosopher-bold underline'>www.sheraniinterioruae.com</strong> or use our services.
            </p>
            <div className="w-3/4 mx-auto flex flex-col items-left">
              <h5 className="philosopher-bold text-xl sm:text-3xl my-3">Information We Collect</h5>
              <ul className="list-disc pl-4">
                <li>Personal information such as name and email address.</li>
                <li>Demographic information.</li>
                <li>Usage data, including IP address and browser information.</li>
                <li>Cookies and tracking technologies.</li>
              </ul>
            </div>
            <div className="w-3/4 mx-auto flex flex-col items-left">
              <h5 className="philosopher-bold text-xl sm:text-3xl my-3">How We Use Your Information</h5>
              <p>We may use the information we collect for various purposes, including:</p>
              <ul className="list-disc pl-4">
                <li>Providing and improving our services.</li>
                <li>Communicating with you.</li>
                <li>Personalizing your experience.</li>
                <li>Analyzing trends and statistics.</li>
                <li>Preventing fraud and ensuring security</li>
              </ul>
            </div>
            <div className="w-3/4 mx-auto flex flex-col items-left">
              <h5 className='philosopher-bold text-xl sm:text-3xl my-3' >Security</h5>
              <p>We take reasonable measures to protect the security of your personal information and prevent unauthorized access, use, or disclosure.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;
