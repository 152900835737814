import React from "react";
import Slider from "react-slick";
import autocad from "../../Image/autocad.png";
import civil from "../../Image/dubai-civil-defence-min-removebg-preview.png";
import concordia from "../../Image/Thumbnail-2-Concordia-removebg-preview.png";
import dewa from "../../Image/dewa.png";
import dmcc from "../../Image/DMCC.png";
import dos from "../../Image/dso-logo.png";
import dubaimunicipality from "../../Image/dubai-Municipality.png";
import nakeel from "../../Image/nakheel.png";

import "./Approvals.css"
import { Link } from "react-router-dom";

const Approvals = () => {
    const settings = {
        dots: false,
        arrow: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="approvalsBackground">
            <div id='client' className='container mt-3'>
            <div className="row">
                <div className="col-12 approval">
                    <h1>Design & Approvals</h1>
                    <p>We provide all kinds of approval services like building permit, architectural design, MEP design, fire & safety, electrical drawing & design</p>
                </div>
                <Slider {...settings}>
                    {[autocad, civil, concordia, dewa, dmcc, dos, dubaimunicipality, nakeel].map((image, index) => (
                        <div className='d-flex align-items-center justify-content-center h-32' key={index}>
                            <img src={image} style={{height: "140px" }} className="d-block object-fit-scale w-auto" alt={`${image}`} />
                        </div>
                    ))}
                </Slider>
                <div className="col-12 btnPara mt-3 d-flex flex-column align-items-center justify-content-center">
                    <p>For more inquiry, click the below button</p>
                    <Link to={"tel:+971 52 925 1786"}><button className="button">Call now</button></Link>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Approvals
