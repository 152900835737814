import React from "react";
import CountUp from "react-countup";
import "../../App.css";

const CompanySuccess = () => {

  return (
    <>
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between gap-5 mt-4">
          <div className={`border-l rounded-lg widthAdjustment d-flex flex-column align-items-center justify-content-center text-5xl`}>
              <h1>
                <CountUp start={0} end={100} duration={2} delay={0} />%
              </h1>
            <h4 className="text-base">Satisfied Customers</h4>
          </div>
          <div className={`border-l rounded-lg widthAdjustment d-flex flex-column align-items-center justify-content-center text-5xl`}>
              <h1>
                <CountUp start={0} end={400} duration={2} delay={0} />+
              </h1>
            <h4 className="text-base">Project Complete</h4>
          </div>
          <div className={`border-l rounded-lg widthAdjustment d-flex flex-column align-items-center justify-content-center text-5xl`}>
              <h1>
                <CountUp start={0} end={24} duration={2} delay={0} />/7
              </h1>
            <h4 className="text-base">Working Hours</h4>
          </div>
          <div className={`border-l rounded-lg widthAdjustment d-flex flex-column align-items-center justify-content-center text-5xl`}>
              <h1>
                <CountUp start={0} end={37} duration={2} delay={0} />
              </h1>
            <h4 className="text-base">Years in Market</h4>
          </div>
        </div>
    </>
  );
};

export default CompanySuccess;
