import cabinet from "../Image/cabinet.jpg";
import kitchen from "../Image/kitchen.jpg";
import plaster from "../Image/plaster.webp";
import pipe from "../Image/pipe-repair.jpg";
import plumber from "../Image/plumbing.jpg";
import service from "../Image/service-tools.jpg";

const productList = [
    {
        id: "1",
        name: "Granite & laminate counter installation",
        image: kitchen,
        description: "Elevate your kitchen or bathroom with our granite countertop installation services."
    },
    {
        id: "2",
        name: "New cabinet installation",
        image: cabinet,
        description: "Elevate your interiors with our custom cabinet design services."
    },
    {
        id: "3",
        name: "Existing cabinet refacing",
        image: cabinet,
        description: "Our teams handle every aspect of the cabinet refacing process, from removing old materials to installing."
    },
    {
        id: "4",
        name: "Tile & grout work",
        image: plaster,
        description: "We offer comprehensive tile installation services for floors, walls, backsplashes, showers, and other surfaces."
    },
    {
        id: "5",
        name: "Large application sales and installation",
        image: service,
        description: "We offer a wide range of large application products sourced from reputable manufacturers and suppliers."
    },
    {
        id: "6",
        name: "Plumbing fixture installation",
        image: plumber,
        description: "Choosing the right plumbing fixtures can be overwhelming."
    },
    {
        id: "7",
        name: "Caulking & glazing of existing plumbing fixtures",
        image: pipe,
        description: "Protect your bathtubs and showers from water damage and mold growth with our expert caulking services."
    },
];

export default productList;