import React from "react";
import { Routes, Route } from "react-router-dom";

import './App.css';

import Navigation from './Navigation/Navigation.tsx';
import MaintenancesServices from "./MaintenancesServices/MaintenancesServices.tsx";
import Home from "./Components/Home/Home.tsx";
import Footer from "./Footer/Footer.tsx";
import About from "./About/About.tsx";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy.tsx";
import Contact from "./Contact/Contact.tsx";
import InteriorServices from "./InteriorServices/InteriorServices.tsx";

function App() {
  return (
    <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/interiorServices" element={<InteriorServices />} />
          <Route path="/maintenancesServices" element={<MaintenancesServices />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
