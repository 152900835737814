import React from "react";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp, IoLogoFacebook, IoIosCall } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";

import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footerWapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Sherani Interior Decoration L.L.C</h1>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="profileBlock">
              <ul className="profileList">
                <li className="address">
                  <FaLocationDot className="me-3 locationSvg" />
                  <span>
                    <Link to="https://www.google.com/maps/place/France+R24+-+International+City+-+France+Cluster+-+Dubai+-+United+Arab+Emirates/@25.1633837,55.3961231,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f66b46b3475d7:0x2019a64d844b3188!8m2!3d25.1633789!4d55.398698!16s%2Fg%2F12vqqyz_1?hl=en-US&entry=ttu">
                    FRANCE-R24-SHOP02 INTERNATIONAL CITY
                    Dubai United Arab Emirates
                    </Link>
                 </span>
                </li>
                <li className="phoneNumber">
                  <FaPhone className="me-3" />
                  <span>
                    <Link to="tel:+971 52 925 1786">+971 52 925 1786</Link>
                  </span>
                </li>
                <li className="emails">
                  <MdEmail className="me-3" />
                  <span>
                    <Link to="mailto:info@sheraniinterioruae.com">info@sheraniinterioruae.com</Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-2 d-md-none d-lg-block"></div>
          <div className="col-md-6 col-lg-3">
            <div className="wapper-1">
              <h5 className="Heading">Links</h5>
              <ul className="serviceFooter">
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/interiorServices">Interior Service</a>
                </li>
                <li>
                  <a href="/maintenancesServices">Maintenances Services</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
                <li>
                  <a href="/privacyPolicy">PrivacyPolicy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="wapper-2">
              <h5 className="Heading">Social Media</h5>
              <div className="links">
                <span><Link to="https://api.whatsapp.com/send/?phone=971529251786&text=&type=phone_number&app_absent=0"><IoLogoWhatsapp /></Link></span>
                <span><Link to="https://www.instagram.com/sherani_interiordecoration/"><FaInstagramSquare /></Link></span>
                <span><Link to="https://www.facebook.com/profile.php?id=61556282277884"><IoLogoFacebook /></Link></span>
                <span><Link to="tel:+971 52 925 1786"><IoIosCall /></Link></span>
              </div>
            </div>
          </div>
          <div className="footerEndingText">
            <span>@2024 JV Developer. All rights reserved.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
